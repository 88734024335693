@font-face {
  font-family: 'olietta_script_poesia';
  src: url("../fonts/olietta_script_poesia.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

/* table irregular Verbs Start */

.table {
  width: calc(100% - 10px);
  margin-left: 5px;
  font-size: 1.5em;
  margin: 0 auto;
  text-align: left;
  color: white;
  background: rgba(0, 0, 0, 0.4);
}

.table__row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 1.5em;
  padding-left: 0.3em;
}

.table__row li {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 3px;
  padding-right: 3px;
}

.table__row:nth-child(2n+1) {
  background: rgba(0, 0, 0, 0.3);
}

.voice {
  cursor: pointer;
}

/* table irregular Verbs End */

.tableTest__row {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.7em;
  margin-bottom: 19px;
  background: rgba(255, 255, 255, 0.9);
  padding: 0.2em 0.3em;
  border-radius: 2px;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.5);
  width: calc(100% - 22px);
}

.tableTestMobile .tableTest__slide {
  display: none;
  padding-left: 5px;
}

.tableTestMobile .tableTest__slide:first-child {
  display: block;
}

.tableTest__result {
  background: rgba(255, 255, 255, 0.7);
}

/* Input Ansver */

.row {
  position: relative;
  font-size: 1.9em;
  width: calc(100% - 6px);
  border-radius: 2px;
  height: 44px;
}

.row input {
  border: none;
  width: calc(100% - 17px);
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.5);
  border-radius: 2px;
}

.row:hover {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
}

label {
  color: #999;
  transition: all 0.1s ease-in-out;
}

[type=text],
label {
  position: absolute;
  left: 0;
  top: 0;
  padding: 10px 2px 4px 10px;
}

[type=text] {
  outline: 0;
}

[type=text]::placeholder {
  color: transparent;
}

[type=text]:not(:placeholder-shown) + label {
  font-size: 9px;
  margin: -6px 0 0 1px;
}

/* End */

.button {
  width: calc(100% - 16px);
  padding-left: 5px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
}

.button #btnBack,
.button #btnNext,
.button #testResult,
.button #restartTest,
.button #hint,
.button .psevBtnBack {
  flex-basis: 25%;
  margin-top: 5px;
  margin-bottom: 0;
}

.button #btnBack {
  display: none;
}

.buttonResult {
  display: flex;
  flex-direction: column;
}

.buttonResult a {
  text-decoration: none;
  flex-basis: 20%;
}

.buttonBigResult {
  display: flex;
  flex-direction: column;
}

.buttonBigResult a {
  text-decoration: none;
  flex-basis: 25%;
}

.irrButton {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.irrButton a {
  text-decoration: none;
}

.numbQuestion {
  font-size: 1.3em;
  margin-top: 13px;
  text-align: center;
}

#testResult,
#restartTest,
#nextTest,
#testList,
#bigHome,
#h1Results {
  display: none;
}

.tableTest__result {
  display: flex;
  flex-wrap: wrap;
  font-size: 22px;
  margin-bottom: 2px;
  padding: 0.2em;
}

.tableTest__result__right {
  color: green;
}

.tableTest__result__badAnsver {
  color: red;
}

.tableTest__result__quest {
  color: red;
}

.show {
  width: calc(100% - 35px);
  margin-left: 5px;
}

.show a {
  text-decoration: none;
}

.show__next {
  width: 100%;
  display: inline-block;
  font-size: 1.6em;
  background: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  color: #444444;
  text-decoration: none;
  user-select: none;
  padding: 0.5em;
  margin-bottom: 1em;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  transition: all 0.218s ease 0s;
}

.show__next:hover {
  color: #181818;
  border: 1px solid #c6c6c6;
  background: #f7f7f7 linear-gradient(#f7f7f7, #f1f1f1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.show__next:active {
  color: #333333;
  border: 1px solid #cccccc;
  background: #eeeeee linear-gradient(#eeeeee, #e0e0e0);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}

.show__next__slide {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.show__next__slide h2 {
  display: inline-block;
}

.psevBtnBack {
  text-align: center;
  display: inline-block;
  font-size: 1.6em;
  text-transform: uppercase;
  color: #444;
  padding: 0.5em;
  margin-bottom: 1em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  box-sizing: border-box;
  user-select: none;
}

.result {
  width: calc(100% - 35px);
  margin-left: 5px;
}

.result a {
  text-decoration: none;
}

.result__next {
  color: white;
  text-align: center;
  display: inline-block;
  font-size: 20px;
  background: rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
  text-decoration: none;
  user-select: none;
  padding: 10px 10px;
  margin-bottom: 5px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  transition: all 0.218s ease 0s;
}

.result__next:hover {
  color: #181818;
  border: 1px solid #c6c6c6;
  background: #f7f7f7 linear-gradient(#f7f7f7, #f1f1f1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.result__next:active {
  color: #333333;
  border: 1px solid #cccccc;
  background: #eeeeee linear-gradient(#eeeeee, #e0e0e0);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v36/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0B4gaVc.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v36/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVc.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v36/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1x4gaVc.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v36/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1x4gaVc.ttf) format('truetype');
}

.sweet-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 1000;
}

.sweet-alert {
  background-color: white;
  font-family: 'Open Sans', sans-serif;
  width: 478px;
  padding: 17px;
  border-radius: 5px;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -256px;
  margin-top: -200px;
  overflow: hidden;
  display: none;
  z-index: 2000;
}

.sweet-alert h2 {
  color: #575757;
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  text-transform: none;
  position: relative;
}

.sweet-alert p {
  color: #797979;
  font-size: 16px;
  text-align: center;
  font-weight: 300;
  position: relative;
  margin: 0;
  line-height: normal;
}

.sweet-alert button {
  background-color: #AEDEF4;
  color: white;
  border: none;
  box-shadow: none;
  font-size: 17px;
  font-weight: 500;
  border-radius: 5px;
  padding: 10px 32px;
  margin: 26px 5px 0 5px;
  cursor: pointer;
}

.sweet-alert button:focus {
  outline: none;
  box-shadow: 0 0 2px rgba(128, 179, 235, 0.5), inset 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.sweet-alert button:hover {
  background-color: #a1d9f2;
}

.sweet-alert button:active {
  background-color: #81ccee;
}

.sweet-alert button.cancel {
  background-color: #D0D0D0;
}

.sweet-alert button.cancel:hover {
  background-color: #c8c8c8;
}

.sweet-alert button.cancel:active {
  background-color: #b6b6b6;
}

.sweet-alert button.cancel:focus {
  box-shadow: rgba(197, 205, 211, 0.8) 0px 0px 2px, rgba(0, 0, 0, 0.0470588) 0px 0px 0px 1px inset !important;
}

.sweet-alert[data-has-cancel-button=false] button {
  box-shadow: none !important;
}

.sweet-alert .icon {
  width: 80px;
  height: 80px;
  border: 4px solid gray;
  border-radius: 50%;
  margin: 20px auto;
  position: relative;
  box-sizing: content-box;
}

.sweet-alert .icon.error {
  border-color: #F27474;
}

.sweet-alert .icon.error .x-mark {
  position: relative;
  display: block;
}

.sweet-alert .icon.error .line {
  position: absolute;
  height: 5px;
  width: 47px;
  background-color: #F27474;
  display: block;
  top: 37px;
  border-radius: 2px;
}

.sweet-alert .icon.error .line.left {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 17px;
}

.sweet-alert .icon.error .line.right {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  right: 16px;
}

.sweet-alert .icon.warning {
  border-color: #F8BB86;
}

.sweet-alert .icon.warning .body {
  position: absolute;
  width: 5px;
  height: 47px;
  left: 50%;
  top: 10px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: #F8BB86;
}

.sweet-alert .icon.warning .dot {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: -3px;
  left: 50%;
  bottom: 10px;
  background-color: #F8BB86;
}

.sweet-alert .icon.info {
  border-color: #C9DAE1;
}

.sweet-alert .icon.info::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 29px;
  left: 50%;
  bottom: 17px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: #C9DAE1;
}

.sweet-alert .icon.info::after {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: -3px;
  top: 19px;
  background-color: #C9DAE1;
}

.sweet-alert .icon.success {
  border-color: #A5DC86;
}

.sweet-alert .icon.success::before,
.sweet-alert .icon.success::after {
  content: '';
  border-radius: 50%;
  position: absolute;
  width: 60px;
  height: 120px;
  background: white;
  transform: rotate(45deg);
}

.sweet-alert .icon.success::before {
  border-radius: 120px 0 0 120px;
  top: -7px;
  left: -33px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 60px 60px;
  transform-origin: 60px 60px;
}

.sweet-alert .icon.success::after {
  border-radius: 0 120px 120px 0;
  top: -11px;
  left: 30px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0px 60px;
  transform-origin: 0px 60px;
}

.sweet-alert .icon.success .placeholder {
  width: 80px;
  height: 80px;
  border: 4px solid rgba(165, 220, 134, 0.2);
  border-radius: 50%;
  box-sizing: content-box;
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 2;
}

.sweet-alert .icon.success .fix {
  width: 5px;
  height: 90px;
  background-color: white;
  position: absolute;
  left: 28px;
  top: 8px;
  z-index: 1;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.sweet-alert .icon.success .line {
  height: 5px;
  background-color: #A5DC86;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 2;
}

.sweet-alert .icon.success .line.tip {
  width: 25px;
  left: 14px;
  top: 46px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.sweet-alert .icon.success .line.long {
  width: 47px;
  right: 8px;
  top: 38px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.sweet-alert .icon.custom {
  background-size: contain;
  border-radius: 0;
  border: none;
  background-position: center center;
  background-repeat: no-repeat;
}

/*
 * Animations
 */

@-webkit-keyframes showSweetAlert {
  0% {
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
  }

  45% {
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
  }

  80% {
    transform: scale(0.95);
    -webkit-tranform: scale(0.95);
  }

  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes showSweetAlert {
  0% {
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
  }

  45% {
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
  }

  80% {
    transform: scale(0.95);
    -webkit-tranform: scale(0.95);
  }

  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@keyframes showSweetAlert {
  0% {
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
  }

  45% {
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
  }

  80% {
    transform: scale(0.95);
    -webkit-tranform: scale(0.95);
  }

  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@-webkit-keyframes hideSweetAlert {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }

  100% {
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
  }
}

@-moz-keyframes hideSweetAlert {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }

  100% {
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
  }
}

@keyframes hideSweetAlert {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }

  100% {
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
  }
}

.showSweetAlert {
  -webkit-animation: showSweetAlert 0.3s;
  -moz-animation: showSweetAlert 0.3s;
  animation: showSweetAlert 0.3s;
}

.hideSweetAlert {
  -webkit-animation: hideSweetAlert 0.2s;
  -moz-animation: hideSweetAlert 0.2s;
  animation: hideSweetAlert 0.2s;
}

@-webkit-keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }

  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }

  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@-moz-keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }

  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }

  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }

  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }

  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@-webkit-keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }

  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@-moz-keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }

  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }

  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@-webkit-keyframes rotatePlaceholder {
  0% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg);
  }
}

@-moz-keyframes rotatePlaceholder {
  0% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg);
  }
}

@keyframes rotatePlaceholder {
  0% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg);
  }
}

.animateSuccessTip {
  -webkit-animation: animateSuccessTip 0.75s;
  -moz-animation: animateSuccessTip 0.75s;
  animation: animateSuccessTip 0.75s;
}

.animateSuccessLong {
  -webkit-animation: animateSuccessLong 0.75s;
  -moz-animation: animateSuccessLong 0.75s;
  animation: animateSuccessLong 0.75s;
}

.icon.success.animate::after {
  -webkit-animation: rotatePlaceholder 4.25s ease-in;
  -moz-animation: rotatePlaceholder 4.25s ease-in;
  animation: rotatePlaceholder 4.25s ease-in;
}

@-webkit-keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg);
    -webkit-transform: rotateX(100deg);
    opacity: 0;
  }

  100% {
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    opacity: 1;
  }
}

@-moz-keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg);
    -webkit-transform: rotateX(100deg);
    opacity: 0;
  }

  100% {
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    opacity: 1;
  }
}

@keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg);
    -webkit-transform: rotateX(100deg);
    opacity: 0;
  }

  100% {
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    opacity: 1;
  }
}

.animateErrorIcon {
  -webkit-animation: animateErrorIcon 0.5s;
  -moz-animation: animateErrorIcon 0.5s;
  animation: animateErrorIcon 0.5s;
}

@-webkit-keyframes animateXMark {
  0% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }

  50% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }

  80% {
    transform: scale(1.15);
    -webkit-transform: scale(1.15);
    margin-top: -6px;
  }

  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    margin-top: 0;
    opacity: 1;
  }
}

@-moz-keyframes animateXMark {
  0% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }

  50% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }

  80% {
    transform: scale(1.15);
    -webkit-transform: scale(1.15);
    margin-top: -6px;
  }

  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes animateXMark {
  0% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }

  50% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }

  80% {
    transform: scale(1.15);
    -webkit-transform: scale(1.15);
    margin-top: -6px;
  }

  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    margin-top: 0;
    opacity: 1;
  }
}

.animateXMark {
  -webkit-animation: animateXMark 0.5s;
  -moz-animation: animateXMark 0.5s;
  animation: animateXMark 0.5s;
}

/*@include keyframes(simpleRotate) {
	0%		{ transform: rotateY(0deg);    }
  100% 	{ transform: rotateY(-360deg); }
}
.simpleRotate {
	@include animation('simpleRotate 0.75s');
}*/

@-webkit-keyframes pulseWarning {
  0% {
    border-color: #F8D486;
  }

  100% {
    border-color: #F8BB86;
  }
}

@-moz-keyframes pulseWarning {
  0% {
    border-color: #F8D486;
  }

  100% {
    border-color: #F8BB86;
  }
}

@keyframes pulseWarning {
  0% {
    border-color: #F8D486;
  }

  100% {
    border-color: #F8BB86;
  }
}

.pulseWarning {
  -webkit-animation: pulseWarning 0.75s infinite alternate;
  -moz-animation: pulseWarning 0.75s infinite alternate;
  animation: pulseWarning 0.75s infinite alternate;
}

@-webkit-keyframes pulseWarningIns {
  0% {
    background-color: #F8D486;
  }

  100% {
    background-color: #F8BB86;
  }
}

@-moz-keyframes pulseWarningIns {
  0% {
    background-color: #F8D486;
  }

  100% {
    background-color: #F8BB86;
  }
}

@keyframes pulseWarningIns {
  0% {
    background-color: #F8D486;
  }

  100% {
    background-color: #F8BB86;
  }
}

.pulseWarningIns {
  -webkit-animation: pulseWarningIns 0.75s infinite alternate;
  -moz-animation: pulseWarningIns 0.75s infinite alternate;
  animation: pulseWarningIns 0.75s infinite alternate;
}

.swal2-container,
.swal2-center,
.swal2-fade,
.swal2-shown {
  overflow-y: scroll;
}

/* New style youtube Start */

.video-responsive {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.video-responsive iframe,
.video-responsive object,
.video-responsive embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* New style youtube End */

#ypt_wrapper {
  width: 100%;
  background: #FFF;
  overflow: hidden;
  max-width: 100%;
  box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.5);
  margin-bottom: 1em;
}

#ypt_thumbs {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 20%;
  background: #000;
  margin: 0;
  padding: 0;
  height: 0;
}

#ypt_thumbs li {
  list-style: none;
  margin: 0;
  position: relative;
  font-size: 0;
}

#ypt_thumbs li img {
  width: 100%;
}

#ypt_thumbs li p {
  font-family: arial;
  font-size: 10px;
  background: rgba(0, 0, 0, 0.7);
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  color: #fff;
  padding: 5%;
  margin: 0;
}

#ypt_thumbs li:hover p {
  display: block;
  cursor: pointer;
}

#ypt_thumbs .ypt-now-playing p {
  display: block;
}

#ypt_thumbs .ypt-now-playing > span::after {
  content: "\25b6  Now playing";
  /* A traingle pointing right */
  margin-top: -1em;
  display: block;
  width: 100%;
  padding: 5%;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  position: absolute;
  bottom: 0;
  font-size: 10px;
}

#ypt_wrapper .video {
  position: relative;
  width: 80%;
  /*height: 0;*/
  padding-bottom: 48.4%;
  float: left;
}

#ypt_wrapper .video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  float: left;
}

.away {
  background: rgba(255, 255, 255, 0.7);
  padding: 1.5em;
  border-radius: 5px;
}

.away h2 {
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
}

body,
div,
dl,
dt,
dd,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img,
abbr {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ul li {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

legend {
  color: #000;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

.wrapper {
  max-width: 1366px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
}

.debug {
  height: 100vh;
  position: fixed;
  width: 100%;
  display: none;
}

.debug > div {
  height: inherit;
  max-width: 1366px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
}

.debug > div > div {
  height: inherit;
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.debug > div > div > div {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(100% / 12 * 1 - 30px);
  height: inherit;
  outline: 1px solid #ff0;
  background: rgba(0, 0, 0, 0.5);
}

html {
  width: 100%;
  height: 100%;
}

body {
  font-family: arial, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  margin: 0 auto;
  overflow-y: scroll;
}

.fixed-crossbrowser-background {
  position: fixed;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: cover;
  background-image: url(/img/lala/London-wallpaper-9_2.jpg);
  opacity: 1;
  z-index: -1;
  top: 0;
  left: 0;
}

header {
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 1000;
  color: white;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

header .wrapper {
  height: 41px;
  padding-bottom: 1.4em;
  line-height: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

header .wrapper .arrow,
header .wrapper .bigHome {
  display: inline-block;
  font-size: 2em;
  padding-right: 0.6em;
  padding-left: 0.6em;
}

header .wrapper .arrow:hover,
header .wrapper .bigHome:hover {
  color: orange;
  background: rgba(255, 255, 255, 0.1);
  transition: 0.8s;
}

header .wrapper .arrow:active,
header .wrapper .bigHome:active {
  color: green;
}

header .wrapper a {
  text-decoration: none;
  color: #FF0000;
}

header .wrapper h1 {
  display: inline-block;
  font-size: 1.5em;
  padding-left: 12px;
}

/*   About   */

.mainPage {
  width: calc(100% - 15px);
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.mainPage h1 {
  display: inline-block;
  font-size: 20px;
  padding-left: 10px;
}

.mainPage .about {
  border-radius: 100%;
  width: 26px;
  height: 26px;
  line-height: 26px;
  font-size: 1.4em;
  align-self: center;
  color: #444444;
  text-decoration: none;
  user-select: none;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #f5f5f5 linear-gradient(#f4f4f4, #f1f1f1);
  transition: all 0.218s ease 0s;
}

.mainPage .about:hover {
  color: #181818;
  border: 1px solid #c6c6c6;
  background: #f7f7f7 linear-gradient(#f7f7f7, #f1f1f1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.mainPage .about:active {
  color: #333333;
  border: 1px solid #cccccc;
  background: #eeeeee linear-gradient(#eeeeee, #e0e0e0);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}

.main {
  flex-grow: 1;
  flex-shrink: 0;
  margin-top: 75px;
}

.main .wrapper {
  height: 100%;
}

.main .base_title {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  flex-direction: column;
  align-items: center;
}

.main .base_title a {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  align-items: center;
  width: calc(100% - 10px);
  height: 50px;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.main .base_title a div {
  width: 100%;
  text-decoration: none;
  color: #333;
  margin-left: 5px;
  margin-right: 5px;
}

.advertising {
  bottom: -32px;
  margin-left: 5px;
  position: relative;
}

.advertising__img {
  opacity: 0.5;
}

.advertising__img:hover {
  opacity: 0.7;
}

.advertising__img:active {
  opacity: 1;
}

/*footer Start*/

footer .wrapper .right {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 12px;
  text-shadow: 4px 4px 11px rgba(163, 163, 163, 0.87);
  padding-bottom: 10px;
}

footer {
  width: 100%;
}

/*footer end*/

/* Поворачиваем стрелку + JavaScript*/

.redArrow {
  color: red;
  margin-right: 0.2em;
  transition: all 0.5s linear;
  overflow: hidden;
}

.redArrow.transform {
  transform: rotate(90deg);
  transform-origin: 50% 50%;
}

/* End */

@media screen and (min-width: 721px) {
  .buttonResult {
    width: calc(100% - 13px);
    padding-left: 5px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: flex-end;
  }

  .buttonResult .result__next {
    flex-basis: 20%;
    margin-top: 10px;
    margin-bottom: 0;
  }

  .buttonBigResult {
    width: calc(100% - 13px);
    padding-left: 5px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: flex-end;
  }

  .buttonBigResult .result__next {
    flex-basis: 25%;
    margin-top: 5px;
    margin-bottom: 0;
  }

  .irrButton {
    width: calc(100% - 13px);
    padding-left: 5px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: flex-end;
  }

  .irrButton a {
    flex-basis: 25%;
  }

  .irrButton .result__next {
    flex-basis: 25%;
    margin-top: 5px;
    margin-bottom: 0;
  }

  .main .wrapper {
    width: calc(100% - 35%);
  }
}

@media screen and (max-width: 1366px) {
  .table {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 1200px) {
  .wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }

  .debug > div {
    padding-left: 30px;
    padding-right: 30px;
  }

  .debug > div {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (max-width: 992px) {
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .debug > div {
    padding-left: 15px;
    padding-right: 15px;
  }

  .debug > div {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 720px) {
  .table {
    font-size: 1.125em;
  }

  .table__row {
    padding-left: 1px;
  }

  .tableTest__row {
    font-size: 22px;
    padding: 0.2em;
    width: calc(100% - 17px);
    margin-bottom: 15px;
  }

  .row {
    font-size: 22px;
    padding: 0.2em;
    width: calc(100% - 17px);
    height: 31px;
  }

  .row input {
    width: calc(100% - 12px);
  }

  .button {
    width: calc(100% - 13px);
  }

  .numbQuestion {
    margin-top: 0.5em;
    font-size: 1em;
  }

  .show__next {
    font-size: 20px;
    padding: 10px 10px;
    margin-bottom: 20px;
  }

  .psevBtnBack {
    font-size: 20px;
    padding: 10px 10px;
    margin-bottom: 20px;
  }

  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .debug > div {
    padding-left: 15px;
    padding-right: 15px;
  }

  .debug > div {
    padding-left: 15px;
    padding-right: 15px;
  }

  header .wrapper {
    height: 40px;
    padding-bottom: 10px;
    line-height: 50px;
  }

  header .wrapper .arrow,
  header .wrapper .bigHome {
    font-size: 25px;
    padding-right: 13px;
    padding-left: 13px;
  }

  header .wrapper h1 {
    font-size: 20px;
    padding-left: 10px;
  }

  .mainPage .about {
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 18px;
  }

  .main {
    margin-top: 60px;
  }
}

@media screen and (max-width: 576px) {
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .debug > div {
    padding-left: 15px;
    padding-right: 15px;
  }

  .debug > div {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media all and (max-width: 540px) {
  .sweet-alert {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    left: 15px;
    right: 15px;
  }
}

@media only screen and (max-width: 400px) {
  #ypt_thumbs {
    display: none;
  }

  #ypt_wrapper .video {
    width: 100%;
    padding-bottom: 56.25%;
  }
}

@media screen and (max-width: 380px) {
  .wrapper {
    padding-left: 5px;
    padding-right: 5px;
  }

  .debug > div {
    padding-left: 5px;
    padding-right: 5px;
  }

  .debug > div {
    padding-left: 5px;
    padding-right: 5px;
  }
}