.tableTest__row{
	display: flex;
	flex-wrap: wrap;
	font-size: 1.7em;
	margin-bottom: 19px;
	background: rgba(255, 255, 255, 0.9);
	padding: 0.2em 0.3em;
	border-radius: 2px;
	box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.5);
	width: calc(100% - 22px);
	@media screen and (max-width: 720px){
		font-size: 22px;
		padding: 0.2em;
		width: calc(100% - 17px);
		margin-bottom: 15px;
    }
}
.tableTestMobile .tableTest__slide{
	display: none;
	padding-left: 5px;
}

.tableTestMobile .tableTest__slide:first-child{
	display: block;
}
.tableTest__result{
    background: rgba(255,255,255,.7);
}
/* Input Ansver */
.row{
    position: relative;
    font-size: 1.9em;
	width: calc(100% - 6px);
    border-radius: 2px;
    //box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.5);
    //box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
    height: 44px;
    @media screen and (max-width: 720px){
		font-size: 22px;
		padding: 0.2em;
		width: calc(100% - 17px);
		height: 31px;
    }
    input{
    	border: none;
    	width: calc(100% - 17px);
    	background: rgba(255, 255, 255, 0.9);
    	box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.5);
    	border-radius: 2px;
    	 @media screen and (max-width: 720px){
		width: calc(100% - 12px);
    }
    }
}
.row:hover{
	box-shadow:0 3px 8px 0 rgba(0,0,0,0.2),0 0 0 1px rgba(0,0,0,0.08);
}

label{
   color: #999;
   transition: all .1s ease-in-out;
}
[type=text],label{
   position:absolute;
   left: 0; top: 0;
   padding: 10px 2px 4px 10px
}
[type=text]{
   outline: 0;
}
[type=text]::placeholder,{
   color: transparent
}
[type=text]:not(:placeholder-shown) + label,{
   font-size: 9px;
   margin: -6px 0 0 1px;
}
/* End */
.button{
	width: calc(100% - 16px);
	padding-left: 5px;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: flex-end;
	 @media screen and (max-width: 720px){
		width: calc(100% - 13px);
    }
	#btnBack, #btnNext, #testResult, #restartTest, #hint, .psevBtnBack{
		flex-basis: 25%;
		margin-top: 5px;
		margin-bottom: 0;
	}
	#btnBack{
		display: none;
	}

}
.buttonResult{
		display: flex;
		flex-direction: column;
		a{
			text-decoration: none;
			flex-basis: 20%
		}
		@media screen and (min-width: 721px){
			width: calc(100% - 13px);
    		padding-left: 5px;
	    	flex-direction: row;
	    	flex-wrap: nowrap;
	    	justify-content: space-around;
	    	align-items: flex-end;
	    	.result__next{
	    	flex-basis: 20%;
	    	margin-top: 10px;
			margin-bottom: 0;
	    	}
	    }
}
.buttonBigResult{
		display: flex;
		flex-direction: column;
		a{
			text-decoration: none;
			flex-basis: 25%;
		}
		@media screen and (min-width: 721px){
			width: calc(100% - 13px);
    		padding-left: 5px;
	    	flex-direction: row;
	    	flex-wrap: nowrap;
	    	justify-content: space-around;
	    	align-items: flex-end;
	    	.result__next{
	    	flex-basis: 25%;
	    	margin-top: 5px;
			margin-bottom: 0;
	    	}
	    }
}
.irrButton{
	display: flex;
	flex-direction: column;
	margin-top: 5px;
		a{
			text-decoration: none;
		}
		@media screen and (min-width: 721px){
			width: calc(100% - 13px);
    		padding-left: 5px;
	    	flex-direction: row;
	    	flex-wrap: nowrap;
	    	justify-content: space-around;
	    	align-items: flex-end;
	    	a{
			flex-basis: 25%;
			}
	    	.result__next{
	    	flex-basis: 25%;
	    	margin-top: 5px;
			margin-bottom: 0;
	    	}
	    }
}
.numbQuestion{
		font-size: 1.3em;
		margin-top: 13px;
		text-align: center;
		@media screen and (max-width: 720px){
		margin-top: 0.5em;
		font-size: 1em;
    	}

	}
#testResult, #restartTest, #nextTest, #testList, #bigHome, #h1Results{
	display: none;
}
.tableTest__result{
	display: flex;
	flex-wrap: wrap;
	font-size: 22px;
	margin-bottom: 2px;
	padding: 0.2em;
		&__right{
		color: green;
		}
		&__badAnsver{
			color: red;
		}
		&__quest{
			color: red;
		}
		
}