.show{
	width: calc(100% - 35px);
	margin-left: 5px;
	a{
		text-decoration: none;
	}
	&__next{
		width: 100%;
		display: inline-block;
	  	font-size: 1.6em;
	  	background: rgba(255, 255, 255, 0.7); // добавил прозрачность
	  	text-transform: uppercase;
	  	color: rgb(68,68,68);
	  	text-decoration: none;
	  	user-select: none;
	  	padding: 0.5em;
	  	margin-bottom: 1em;
	  	outline: none;
	  	border: 1px solid rgba(0,0,0,.1);
	  	border-radius: 2px;
	  	//background: rgb(245,245,245) linear-gradient(#f4f4f4, #f1f1f1);
	  	transition: all .218s ease 0s;
	  		@media screen and (max-width: 720px){
	  			font-size: 20px;
				padding: 10px 10px;
		  		margin-bottom: 20px;
	    }

	}
	&__next:hover {
	  color: rgb(24,24,24);
	  border: 1px solid rgb(198,198,198);
	  background: #f7f7f7 linear-gradient(#f7f7f7, #f1f1f1);
	  box-shadow: 0 1px 2px rgba(0,0,0,.1);
	}
	&__next:active {
	  color: rgb(51,51,51);
	  border: 1px solid rgb(204,204,204);
	  background: rgb(238,238,238) linear-gradient(rgb(238,238,238), rgb(224,224,224));
	  box-shadow: 0 1px 2px rgba(0,0,0,.1) inset;
	}
}
.show__next__slide{
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
		    h2{
	    	display: inline-block;
	    }
}
.psevBtnBack{
	text-align: center;
    display: inline-block;
    font-size: 1.6em;
    text-transform: uppercase;
    color: #444;
    padding: 0.5em;
    margin-bottom: 1em;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 2px;
    box-sizing: border-box;
    user-select: none;  //запрещаю выделение текста
	@media screen and (max-width: 720px){
		font-size: 20px;
		padding: 10px 10px;
  		margin-bottom: 20px;
    }
}

.result{
	width: calc(100% - 35px);
	margin-left: 5px;
	a{
		text-decoration: none;
	}
	&__next{
		color: white;
		//width: 100%;
		text-align: center;
		display: inline-block;
	  	font-size: 20px;
	  	background: rgba(0, 0, 0, 0.7); // добавил прозрачность
	  	text-transform: uppercase;
	  	//color: rgb(68,68,68);
	  	text-decoration: none;
	  	user-select: none;
	  	padding: 10px 10px;
	  	margin-bottom: 5px;
	  	outline: none;
	  	border: 1px solid rgba(0,0,0,.1);
	  	border-radius: 2px;
	  	//background: rgb(245,245,245) linear-gradient(#f4f4f4, #f1f1f1);
	  	transition: all .218s ease 0s;
	}
	&__next:hover {
	  color: rgb(24,24,24);
	  border: 1px solid rgb(198,198,198);
	  background: #f7f7f7 linear-gradient(#f7f7f7, #f1f1f1);
	  box-shadow: 0 1px 2px rgba(0,0,0,.1);
	}
	&__next:active {
	  color: rgb(51,51,51);
	  border: 1px solid rgb(204,204,204);
	  background: rgb(238,238,238) linear-gradient(rgb(238,238,238), rgb(224,224,224));
	  box-shadow: 0 1px 2px rgba(0,0,0,.1) inset;
	}
}