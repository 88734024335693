@import "smart-grid.less";
@import "project.less";
@import "irrTable.less";
@import "irrMobileTest.less";
@import "button.less";
@import "sweet-alert.less";
@import "youtube.less";
@import "away.less";

.reset();

.wrapper{
    .wrapper();
}
.debug{
    .debug(rgba(0, 0, 0, 0.5), 1px solid #ff0);
    display: none; 
}
html{
	width: 100%;
	height: 100%;
} 
body{
	font-family: arial,sans-serif;
	//font-style: 16px;
	display: flex;
	flex-direction: column;
	min-height: 100%;
	width: 100%;
	margin: 0 auto;
	overflow-y:scroll;
}
.fixed-crossbrowser-background {
    position: fixed;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center 0;
    background-size:cover;
    background-image: url(/img/lala/London-wallpaper-9_2.jpg);
    opacity: 1;
    z-index: -1;
    top: 0;
    left: 0;
}
// @media screen and (orientation:landscape) {
//     /* стили для горизонтальной */
//     body{
//     	height: 100%;
// 	}
//      header{
//     	display: none;
//     }
//     .main {
//     	border: 1px solid green;
//     	margin-top: 0px;
// 	}
// 	footer{
// 		display: none;
// 	}
// 	.wrapper {
// 		border: 1px solid yellow;
// 	    padding-left: 0px;
// 	    padding-right: 0px;
// 	    position: relative;
// 	}
// 	#ypt_wrapper{
//  position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
// 	}
// }

header{
	width: 100%;
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	z-index: 1000;
	color: white;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	.wrapper{
	height: 41px;
	padding-bottom: 1.4em;
	line-height: 60px; //Выравнивение текста по вертикали
	text-overflow: ellipsis; //образка длинных блоков с текстом
	overflow: hidden; //прятать выходящий за пределы текст
	white-space: nowrap; //запрет на перенос текста
	@media screen and (max-width: 720px){
			height: 40px;
			padding-bottom: 10px;
			line-height: 50px; //Выравнивение текста по вертикали
	    }

	
	.arrow, .bigHome{
		display: inline-block;
		font-size: 2em; 
		padding-right: 0.6em;
		padding-left: 0.6em;
		@media screen and (max-width: 720px){
				font-size: 25px;
				padding-right: 13px;
				padding-left: 13px;
		    }

	}
	.arrow:hover, .bigHome:hover{
	  color: orange;
	  background: rgba(255, 255, 255, 0.1);
	  transition: 0.8s;
	}
	.arrow:active, .bigHome:active{
	  color: green;
	}

	a{	
		text-decoration: none;
		color: #FF0000; 
	}
	h1{
		display: inline-block;
		font-size: 1.5em;
		padding-left: 12px;
			@media screen and (max-width: 720px){
				font-size: 20px;
				padding-left: 10px;
		    }
	   	}
	}
}
/*   About   */
.mainPage{
		width: calc(100% - 15px);
	    display: flex;
	    flex-wrap: nowrap;
	    justify-content: space-between;
		h1{
		display: inline-block;
		font-size: 20px;
		padding-left: 10px;
	   	}
	   	.about{
		    border-radius: 100%;
		    width: 26px;
		    height: 26px;
		    line-height: 26px;
		    font-size: 1.4em;
		    align-self: center;
		    color: rgb(68,68,68);
	  		text-decoration: none;
	  		user-select: none;
	  		outline: none;
	  		border: 1px solid rgba(0,0,0,.1);
	  background: rgb(245,245,245) linear-gradient(#f4f4f4, #f1f1f1);
	  	transition: all .218s ease 0s;
	  		@media screen and (max-width: 720px){
				width: 20px;
			    height: 20px;
			    line-height: 20px;
			    font-size: 18px;
	    	}
	   	}
	   	.about:hover {
		  color: rgb(24,24,24);
		  border: 1px solid rgb(198,198,198);
		  background: #f7f7f7 linear-gradient(#f7f7f7, #f1f1f1);
		  box-shadow: 0 1px 2px rgba(0,0,0,.1);
		}
		.about:active {
		  color: rgb(51,51,51);
		  border: 1px solid rgb(204,204,204);
		  background: rgb(238,238,238) linear-gradient(rgb(238,238,238), rgb(224,224,224));
		  box-shadow: 0 1px 2px rgba(0,0,0,.1) inset;
		}
}		
.main{
	flex-grow: 1;
	flex-shrink: 0;
	margin-top: 75px;
		@media screen and (max-width: 720px){
			margin-top: 60px;
	    }
	 .wrapper{
	 	height: 100%;
	 	@media screen and (min-width: 721px){
			width: calc(100% - 35%);
	    }
	 }

	.base_title{
		.row-flex();
		.col();
		flex-direction: column;
		align-items: center;
	
		a{
			.row-flex();
			.col();
			align-items: center;
			width: calc(100% - 10px);
			height: 50px;
			font-size: 20px;
			text-transform: uppercase;
			margin-bottom: 20px;
			//background: @color_background_menu;
			div{	
				width: 100%;
				text-decoration: none;
				color: #333;
				margin-left: 5px;
				margin-right: 5px;
			}
		}
	}
}
.advertising{
	//width: calc(100% - 85%);
	bottom:-32px;
	margin-left: 5px;
	position:relative;
	&__img{
		opacity: 0.5;
	}
	&__img:hover{
		opacity: 0.7;
	}
	&__img:active{
		opacity: 1;
	}
}

/*footer Start*/
footer .wrapper .right{
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 10px;
	margin-left: 15px;
	margin-right: 15px;
	font-size: 12px;
	text-shadow: 4px 4px 11px rgba(163, 163, 163, 0.87);
	padding-bottom: 10px;
	&__copyright{

	}
}
footer{
	width: 100%;
}

/*footer end*/

/* Поворачиваем стрелку + JavaScript*/
.redArrow {
	color: red;
	margin-right: 0.2em;
  	transition: all 0.5s linear;
   	overflow:hidden;
}

.redArrow.transform {
  transform: rotate(90deg);
  transform-origin: 50% 50%;
}
/* End */