 /* table irregular Verbs Start */
.table{
	width: calc(100% - 10px);
	margin-left: 5px;
	font-size: 1.5em;
	margin: 0 auto;
    text-align: left;
    color: white;
    //background-color: #E6E6E6;
    //background: rgba(230,230,230, 0.9);
    background: rgba(0, 0, 0, 0.4);
    @media screen and (max-width: 1366px){
		font-size: 1.5em;
    }
	@media screen and (max-width: 720px){
		font-size: 1.125em;
    }
}
.table__row{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	line-height: 1.5em;
	padding-left: 0.3em;
	@media screen and (max-width: 720px){
		padding-left: 1px;
    }
}
.table__row li{
	padding-top: 2px;
	padding-bottom:2px;
	padding-left: 3px;
	padding-right: 3px;
}
.table__row:nth-child(2n+1){
	//background: #f7f7f7;
	//background: rgba(247,247,247, 0.9);
	background: rgba(0, 0, 0, 0.3);
}
.voice{
	cursor: pointer;
}
/* table irregular Verbs End */